import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import Story from '../components/Story';

const singleStory = ({ data, location }) => {

  const { sanityStory } = data;

  return (
    <Wrapper seo={{seoSettings: sanityStory.seoSettings, title: sanityStory.title }}  location={location}>
      <Story {...data.sanityStory} />
    </Wrapper>
  );
};

export const StoryQuery = graphql`
  query storyQuery($slug: String!) {
    sanityStory(slug: {current: {eq: $slug}}) {
      id
      _rawBody
      mainImage {
        alt
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      imageWidth
      title
      publishedAt(formatString: "MMMM D, YYYY")
    }
  }
`;

export default singleStory;