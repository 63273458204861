import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import classNames from 'classnames';

import PageSidebar from './PageSidebar';
import Content from './Content';

import { PageWrapper } from './Page';
import useSiteContext from './SiteContext';


const Story = ({ title, mainImage, imageWidth, _rawBody, className, publishedAt }) => {

  const { viewport } = useSiteContext();

  return (
    <PageWrapper className={classNames('page-wrapper', className)} viewHeight={viewport.height}>
      <PageSidebar title={title} noMobile />
      <StyledArticle className="page-content">
        <h1 className="page-title post-title">{title}</h1>
        <span className="post-date">{publishedAt}</span>
        {mainImage && (
          <MainImage className="post-image" imageWidth={imageWidth ? imageWidth : 100} fluid={mainImage.asset.fluid} alt={mainImage.alt} />
        )}
        <Content className="post-content">
          {_rawBody}
        </Content>
      </StyledArticle>
    </PageWrapper>
  );
};

const MainImage = styled(Img)`
  width: ${({ imageWidth}) => imageWidth}%;
`;

const StyledArticle = styled.article`
  padding: 1rem;
  .post-date {
    margin-bottom: 2rem;
    display: block;
  }
  .post-image {
    margin-bottom: 2rem; 
  }
  .post-content {
    padding: 0rem;
  }
`;

export default Story;